<template>
  <div class="logs-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Model Change Log</h3>
      </a-col>
<!--      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">-->
<!--        <a-button type="primary" class="ml-4" size="large" @click="createSportModal">-->
<!--          <a-icon type="plus" /> Add new-->
<!--        </a-button>-->
<!--      </a-col>-->
    </a-row>
    <a-card class="mt-2">
      <div class="d-flex mb-2">
        <div>
          <label for="model_type" class="mt-1 mr-1">Model: </label>
          <a-select v-model="cModel" id="model_type" placeholder="Model" class="width-150" @change="handleModelChange">
            <a-select-option :value="''" >
              All
            </a-select-option>
            <a-select-option v-for="model in models" :key="model.model" >
              {{ model.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-2">
          Model ID:
          <a-input id="model_id" v-model="cModelId" style="display: inline-block !important; width: 100px" placeholder="Model ID" class="width-100" @pressEnter="refresh" />
        </div>
        <div class="ml-2">
          <label for="action" class="mt-1 mr-1">Action: </label>
          <a-select v-model="cAction" id="action" placeholder="Action" class="width-150">
            <a-select-option :value="''" >
              All
            </a-select-option>
            <a-select-option v-for="action in actions" :key="action" >
              {{ action }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-2">
          <a-button @click="refresh"><a-icon type="check" /> Применить</a-button>
        </div>
      </div>
      <model-log-table :model-type="cModel" :action="cAction" :model-id="cModelIdInt" :refresh="cRefresh" />
    </a-card>
  </div>
</template>

<script>
import modelLogTable from '@/components/custom/modelLogTable'

const actions = ['create', 'update', 'update_media', 'delete']
const models = [
  {
    name: 'Banners',
    model: 'App\\Models\\Banner\\Banner',
  },
  {
    name: 'Promo-codes',
    model: 'App\\Models\\Promocode\\Promocode',
  },
]

export default {
  name: 'modelLogs',
  components: {
    modelLogTable,
  },
  computed: {
    cModelIdInt() {
      return Number(this.cModelId)
    },
  },
  data() {
    return {
      accessModule: 'model_logs',
      models,
      actions,
      cModel: '',
      cModelId: '',
      cAction: '',
      cRefresh: 0,
    }
  },
  mounted () {
    // ---
  },
  created() {
    //
  },
  methods: {
    refresh() {
      this.cRefresh++
    },
  },
}
</script>
