<template>
  <div>
    <a-table
      :columns="logsColumns"
      :data-source="logsData"
      :row-key="record => record.id"
      :loading="logsLoading"
      :pagination="pagination"
      @change="handleLogsChange"
      :bordered="false"
    >
      <template v-slot:created_at="created_at">
        {{ $moment(created_at).format('DD.MM.YY') }}
        <span class="small text-gray-5">{{ $moment(created_at).format('HH:mm') }}</span>
      </template>
      <template v-slot:user="user">
        <span v-if="user.name">{{ user.name }}</span>
        <span v-if="user.email" class="d-block small text-gray-8">{{ user.email }}</span>
      </template>
      <template v-slot:action="action">
        <span v-if="action && action === 'create'" class="text-success">{{ action }}</span>
        <span v-if="action && action === 'update'" class="text-primary">{{ action }}</span>
        <span v-if="action && action === 'update_media'" class="text-primary">{{ action }} <a-icon type="picture" /></span>
        <span v-if="action && action === 'delete'" class="text-danger">{{ action }}</span>
      </template>
      <template v-slot:model_type="model_type, record">
        <div v-if="model_type">
          <span>{{ model_type.split('\\').pop() }}</span>
          <span v-if="record.model_id" class="d-block small text-gray-8">Model ID: <b>{{ record.model_id }}</b></span>
        </div>
      </template>
      <template v-slot:request="request">
        <div v-if="request" class="small">
          <div class="d-flex">
            <div>
              <span class="text-gray-5">{{ request.req_method.toUpperCase() }}</span>
              <span class="ml-1">/{{ request.req_url }}</span>
              <div class="text-left text-gray-5">IP: {{ request.host }}</div>
            </div>
            <div class="ml-auto"><span class="ml-1 font-size-18"><a href="javascript:" @click="showRequestInfo(request)"><a-icon type="file-text" /></a></span></div>
          </div>
        </div>
      </template>
    </a-table>
    <a-modal v-model="requestInfoModal"
             :destroyOnClose="true"
             title="Request Data Information"
             :footer="null"
             width="680px"
             height="680px"
    >
      <request-data-modal
        :request-info="requestInfo"
      />
    </a-modal>
  </div>
</template>

<script>
import AccountService from '@/services/api/apiAccountService'
import RequestDataModal from './modelLogRequestModal'

const logsColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '15%',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: '20%',
    scopedSlots: { customRender: 'user' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    align: 'left',
    scopedSlots: { customRender: 'action' },
  },
  {
    title: 'Model',
    dataIndex: 'model_type',
    key: 'model_type',
    width: '20%',
    align: 'left',
    scopedSlots: { customRender: 'model_type' },
  },
  {
    title: 'Request Info',
    dataIndex: 'request',
    key: 'request',
    width: '30%',
    align: 'right',
    scopedSlots: { customRender: 'request' },
  },
]

export default {
  name: 'modelLogTable',
  props: ['modelType', 'modelId', 'action', 'userId', 'refresh'],
  components: {
    RequestDataModal,
  },
  watch: {
    // modelType(newType, oldType) {
    //   console.log('type changed from ' + oldType + ' to ' + newType)
    //   this.refreshData()
    // },
    // modelId(newId, oldId) {
    //   this.refreshData()
    // },
    // action(newAction, oldAction) {
    //   this.refreshData()
    // },
    refresh(newF, oldF) {
      this.refreshData()
    },
  },
  data() {
    return {
      logsColumns,
      logsData: [],
      logsLoading: false,
      pagination: { pageSize: 10 },
      requestInfoModal: false,
      requestInfo: undefined,
    }
  },
  mounted() {
    this.refreshData()
  },
  methods: {
    handleLogsChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
        model_type: this.modelType,
        model_id: this.modelId,
        action: this.action,
        user_id: this.userId,
      })
    },
    async fetch(params = {}) {
      this.logsLoading = true
      const reqData = { results: 10, ...params }
      return AccountService.getModelLogs(reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.logsLoading = false
        this.logsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.logsLoading = false
      })
    },
    async refreshData() {
      return this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        model_type: this.modelType,
        model_id: this.modelId,
        action: this.action,
        user_id: this.userId,
      })
    },
    showRequestInfo(requestData) {
      this.requestInfo = requestData
      this.requestInfoModal = true
    },
  },
}
</script>

<style scoped>

</style>
